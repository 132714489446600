import React, { useState, useCallback } from "react";

import { useDemoConfig, useOverrideDemoPut } from "../../demo";
import { useDataContextContext, useGetData } from "../../react-data-context";

import DataContextDemoEditor from "./DataContextDemoEditor";

function DataContextDemoEditorBase() {
  const [demoConfig, setDemoConfig] = useDemoConfig();
  const overrideDemoPut = useOverrideDemoPut();
  const { config } = useDataContextContext();
  const handleChangeDemoConfig = useCallback(
    (config) => setDemoConfig(config),
    [setDemoConfig]
  );
  const [fetchId, setFetchId] = useState("");
  const handleChangeFetchId = useCallback(
    (e) => setFetchId(e.target.value),
    [setFetchId]
  );
  const [fetching, setFetching] = useState(false);
  const getData = useGetData();
  const handleFetchMissing = useCallback(async () => {
    if (!demoConfig?.name) {
      setFetchResult(`You must set a demo name before fetching`);
      return;
    }
    const { queryNameTuple, queryArgsJSON } = fetchId.match(
      /^(?<queryNameTuple>[^{]+)-(?<queryArgsJSON>.*)/
    ).groups;
    const queryName = queryNameTuple.substring(
      0,
      (queryNameTuple.length - 1) / 2
    );
    const queryArgs = (() => {
      try {
        return JSON.parse(queryArgsJSON);
      } catch (e) {
        setFetchResult(`Invalid query ID`);
      }
    })();
    if (!queryArgs) {
      return;
    }

    setFetchResult(
      `Will fetch queryName “${queryName}” args ${JSON.stringify(queryArgs)}`
    );

    setFetching(true);
    try {
      const response = await getData(queryName, queryArgs);
      await overrideDemoPut({
        config,
        queryName,
        queryArgs,
        response,
        force: true,
      });
      if (response?.data?.error) {
        setFetchResult(`Fetch Error: ${response?.data?.error}`);
      } else {
        setFetchResult("Fetch OK, you can cherrypick");
      }
    } catch (e) {
      setFetchResult(`Could not fetch: ${e}`);
    } finally {
      setFetching(false);
    }
  }, [demoConfig, fetchId, setFetching, getData, config, overrideDemoPut]);
  const [fetchResult, setFetchResult] = useState("");
  return (
    <DataContextDemoEditor
      demoConfig={demoConfig}
      onChangeDemoConfig={handleChangeDemoConfig}
      fetchId={fetchId}
      fetchResult={fetchResult}
      fetching={fetching}
      onFetchMissing={handleFetchMissing}
      onChangeFetchId={handleChangeFetchId}
    />
  );
}

export default DataContextDemoEditorBase;
