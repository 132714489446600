import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";

function DataContextDemoEditor({
  demoConfig,
  onChangeDemoConfig,
  fetchId,
  fetching,
  fetchResult,
  onFetchMissing,
  onChangeFetchId,
}) {
  const handleClickDefault = useCallback(
    () =>
      onChangeDemoConfig((demoConfig) => ({ ...demoConfig, mode: undefined })),
    [onChangeDemoConfig]
  );
  const handleClickSave = useCallback(
    () => onChangeDemoConfig((demoConfig) => ({ ...demoConfig, mode: "save" })),
    [onChangeDemoConfig]
  );
  const handleClickReplay = useCallback(
    () =>
      onChangeDemoConfig((demoConfig) => ({ ...demoConfig, mode: "replay" })),
    [onChangeDemoConfig]
  );
  const handleChangeDemoName = useCallback(
    (e) =>
      onChangeDemoConfig((demoConfig) => ({
        ...demoConfig,
        name: e.target.value || undefined,
      })),
    [onChangeDemoConfig]
  );
  const handleChangeDemoQueryArgsOverride = useCallback(
    (e) =>
      onChangeDemoConfig((demoConfig) => ({
        ...demoConfig,
        queryArgsOverride: e.target.value || undefined,
      })),
    [onChangeDemoConfig]
  );
  const { mode, name = "", queryArgsOverride = "" } = demoConfig;
  const queryArgsOverrideValid = useMemo(() => {
    try {
      queryArgsOverride && JSON.parse(queryArgsOverride);
      return true;
    } catch (e) {
      return false;
    }
  }, [queryArgsOverride]);

  return (
    <>
      <div>
        demo config: {JSON.stringify(demoConfig)}
        <p>
          <input
            id="mode-default"
            name="config"
            type="radio"
            checked={!mode}
            onChange={handleClickDefault}
          />
          <label htmlFor="mode-default">DEFAULT (disable demo mode)</label>
        </p>
        <p>
          <input
            id="mode-save"
            name="config"
            type="radio"
            checked={mode === "save"}
            onChange={handleClickSave}
          />
          <label htmlFor="mode-save">SAVE (record requests)</label>
        </p>
        <p>
          <input
            id="mode-replay"
            name="config"
            type="radio"
            checked={mode === "replay"}
            onChange={handleClickReplay}
          />
          <label htmlFor="mode-replay">REPLAY (load recorded requests)</label>
        </p>
        <p>
          Demo Name:{" "}
          <input
            type="text"
            placeholder="demo"
            value={name}
            onChange={handleChangeDemoName}
          />
        </p>
        <p>
          <div>
            Demo Query Args Override:{" "}
            {!queryArgsOverrideValid && <span>(warning: invalid JSON)</span>}
          </div>
          <textarea
            style={useMemo(
              () => ({
                width: 600,
                height: 200,
                backgroundColor: queryArgsOverrideValid ? "" : "pink",
              }),
              [queryArgsOverrideValid]
            )}
            onChange={handleChangeDemoQueryArgsOverride}
          >
            {queryArgsOverride}
          </textarea>
        </p>
      </div>
      <div>
        <h2>Fetch Missing Demo Data</h2>
        <div>
          Got a <i>Demo Data Not Found</i> error? Fill the ID in the box below
          and hit Fetch Missing, I&apos;ll do my best to help you fill out the
          missing bits.
        </div>
        <div>
          <input
            style={useMemo(() => ({ width: 600 }), [])}
            type="text"
            value={fetchId}
            onChange={onChangeFetchId}
          />
        </div>
        <div>
          <input
            type="button"
            disabled={fetching}
            value="Fetch Missing"
            onClick={onFetchMissing}
          />
        </div>
        <div>{fetchResult}</div>
      </div>
    </>
  );
}

DataContextDemoEditor.propTypes = {
  demoConfig: PropTypes.string.isRequired,
  onChangeDemoConfig: PropTypes.func.isRequired,
  fetchId: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  fetchResult: PropTypes.string,
  onFetchMissing: PropTypes.func.isRequired,
  onChangeFetchId: PropTypes.func.isRequired,
};

DataContextDemoEditor.defaultProps = {
  fetchResult: null,
};

export default DataContextDemoEditor;
