import { useCallback, useMemo } from "react";

import axios from "axios";

import { useDataContextContext } from "./react-data-context";

const localStorageDemoKey = "@mobsuccess-devops/react-data-context/demoConfig";

function encodeId({ queryName, queryArgs }) {
  return `${queryName}/${queryName}-${JSON.stringify(queryArgs)}`;
}

export function readDemoConfig() {
  if (window._msDemoConfig) {
    return window._msDemoConfig;
  }
  try {
    return JSON.parse(localStorage.getItem(localStorageDemoKey)) || {};
  } catch (e) {
    return {};
  }
}

export function saveDemoConfig(newDemoConfig) {
  window._msDemoConfig = newDemoConfig;
  localStorage.setItem(localStorageDemoKey, JSON.stringify(newDemoConfig));
}

export function useOverrideDemoGet() {
  const demoMode = useDemoMode();
  const demoName = useDemoName();
  const queryArgsOverride = useDemoQueryArgsOverride();

  return useCallback(
    async ({ config: { host }, queryName, queryArgs: queryArgsOrig }) => {
      if (demoMode !== "replay") {
        return;
      }
      switch (queryName) {
        case "HeaderClientSelector":
          // these requests are not overridable by demo mode
          return;
      }
      const queryArgs = Object.fromEntries(
        Object.entries(queryArgsOrig).map(([name, value]) => [
          name,
          name in queryArgsOverride ? queryArgsOverride[name] : value,
        ])
      );
      const response = await axios({
        method: "get",
        url: `${host}/webservices/rest/get-dataContextDemo?name=${encodeURIComponent(
          demoName
        )}&id=${encodeURIComponent(encodeId({ queryName, queryArgs }))}`,
        withCredentials: true,
      });
      return response.data;
    },
    [demoName, demoMode, queryArgsOverride]
  );
}

export function useOverrideDemoPut() {
  const demoMode = useDemoMode();
  const demoName = useDemoName();

  return useCallback(
    async ({
      config: { host },
      queryName,
      queryArgs,
      response,
      force = false,
    }) => {
      if (!force && demoMode !== "save") {
        return;
      }

      await axios({
        method: "post",
        withCredentials: true,
        data: response,
        url: `${host}/webservices/rest/put-dataContextDemo?name=${encodeURIComponent(
          demoName
        )}&id=${encodeURIComponent(encodeId({ queryName, queryArgs }))}`,
      });
    },
    [demoMode, demoName]
  );
}

export function useDemoConfig() {
  const { demoConfig, setDemoConfig } = useDataContextContext();
  return [demoConfig, setDemoConfig];
}

export function useDemoName() {
  return useDemoConfig()[0].name || "demo";
}

export function useDemoMode() {
  return useDemoConfig()[0].mode;
}

export function useDemoQueryArgsOverride() {
  const override = useDemoConfig()[0].queryArgsOverride;
  return useMemo(() => (!override ? {} : JSON.parse(override)), [override]);
}
